<template>
	<div style="overflow-x: auto">
		<ul v-for="data in tree_data" :key="data.id" class="cg-ul">
			<CustomerGroup :data="data" />
		</ul>
	</div>
</template>

<script>
	import CustomerGroup from "@/components/datasets/list/CustomerGroup.vue";
	import { mapGetters } from "vuex";

	export default {
		name: "CustomerGroupList",
		components: {
			CustomerGroup,
		},
		computed: {
			...mapGetters(["tree_data"]),
		},
	};
</script>

<style lang="scss" scoped>
	.cg-ul {
		min-width: 550px;
		padding: 0;
	}
</style>>
