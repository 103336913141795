<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Manage datasets
		</h1>
		<div>
			<CustomerGroupList
				v-if="customer_groups_ready"
			/>
		</div>
	</div>
</template>

<script>
import CustomerGroupList from '@/components/datasets/list/CustomerGroupList.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'List',
	meta: {
		title: `Alert Cascade - Manage Datasets`
	},
	components: {
		CustomerGroupList,
	},
	data () {
		return {
			customer_groups_ready: false,
		}
	},
	computed: {
		...mapGetters(['customer_groups']),
	},
	methods: {
		...mapActions(['getCustomerGroups']),
	},
	async created () {
		await this.getCustomerGroups();
	},
	watch: {
		customer_groups: {
			deep: true,
			handler() {
				this.customer_groups_ready = !!Object.keys(this.customer_groups).length;
			}
		}
	}
}
</script>

