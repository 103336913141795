<template>
	<div :class="root_el_class_list">
		<div
			@click="toggleCollapse"
			@mouseenter="mouseEnter"
			@mouseleave="mouseLeave"
			:class="title_el_class_list"
			:id="`cg_${data.id}_title`"
		>
			<div class="row items-center">
				<div class="text-grey-10 q-mr-sm">{{ data.label }}</div>
				<q-icon
					name="expand_less"
					v-if="data.children"
					size="sm"
					:class="icon_btn_class_list"
				/>
			</div>
			<div>
				<div :class="select_parent_btn_class_list">
					<q-btn
						v-if="pending_parent_change_cg.id !== cg_data.id"
						@click.stop="selectParentClickHandler"
						flat
						color="dark"
						label="Select Parent"
					/>
					<q-btn
						v-else
						@click.stop="cancelParentChange"
						flat
						color="dark"
						label="Cancel"
					/>
				</div>
				<div :class="btns_wrapper_class_list">
					<q-btn
						v-if="data.parentid"
						@click.stop="changeParentClickHandler"
						flat
						color="dark"
						label="Change Parent"
					/>
					<q-btn
						@click.stop="add_dialog = true"
						flat
						round
						color="dark"
						icon="add"
					>
						<q-tooltip anchor="top middle" :delay="500">
							New child dataset
						</q-tooltip>
					</q-btn>
					<q-btn
						v-if="logged_user.customer_group != data.id"
						@click.stop="edit_dialog = true"
						flat
						round
						color="dark"
						icon="o_edit"
					>
						<q-tooltip anchor="top middle" :delay="500">
							Rename dataset
						</q-tooltip>
					</q-btn>
					<q-btn
						flat
						round
						color="dark"
						:to="{ name: 'Edit Dataset', params: { id: this.data.id } }"
						icon="o_settings"
					>
						<q-tooltip anchor="top middle" :delay="500">
							Dataset settings
						</q-tooltip>
					</q-btn>
					<q-btn
						v-if="logged_user.customer_group != data.id"
						@click.stop="delete_dialog = true"
						flat
						round
						color="dark"
						icon="o_delete"
					>
						<q-tooltip anchor="top middle" :delay="500">
							Delete dataset
						</q-tooltip>
					</q-btn>
				</div>
			</div>
		</div>
		<div :id="`cg_${data.id}_children`" :class="children_list_class_list">
			<CustomerGroup
				v-for="child in child_cg_list"
				@resize="resize"
				:key="child.id"
				:data="child"
			/>
		</div>
		<!-- Add dataset dialog -->
		<q-dialog v-model="add_dialog">
			<q-card style="min-width: 350px">
				<q-card-section>
					<div class="text-h6">Add new dataset to {{ data.label }}</div>
				</q-card-section>

				<q-card-section class="q-pt-none">
					<q-input
						dense
						label="Dataset Name"
						v-model="add_dataset_name"
						autofocus
						@keyup.enter="addDataset"
					/>
				</q-card-section>

				<q-card-actions align="right" class="text-primary">
					<q-btn flat label="Cancel" v-close-popup />
					<q-btn flat label="Add dataset" @click="addDataset" />
				</q-card-actions>
			</q-card>
		</q-dialog>
		<!-- !Add dataset dialog -->

		<!-- Edit dataset dialog -->
		<q-dialog v-model="edit_dialog">
			<q-card style="min-width: 350px">
				<q-card-section>
					<div class="text-h6">Edit dataset name</div>
				</q-card-section>

				<q-card-section class="q-pt-none">
					<q-input
						dense
						label="Dataset Name"
						v-model="edited_dataset_name"
						autofocus
						@keyup.enter="editDatasetName"
					/>
				</q-card-section>

				<q-card-actions align="right" class="text-primary">
					<q-btn flat label="Cancel" v-close-popup />
					<q-btn flat label="Save" @click="editDatasetName" />
				</q-card-actions>
			</q-card>
		</q-dialog>
		<!-- !Edit dataset dialog -->

		<!-- Delete dataset dialog -->
		<q-dialog v-model="delete_dialog">
			<q-card>
				<q-card-section class="row items-center">
					<span
						>Are you sure you want to delete <b>{{ data.label }}</b
						>?</span
					>
				</q-card-section>

				<q-card-actions align="right">
					<q-btn flat label="Cancel" color="primary" v-close-popup />
					<q-btn flat label="Delete" color="primary" @click="deleteDataset" />
				</q-card-actions>
			</q-card>
		</q-dialog>
		<!-- !Delete dataset dialog -->
	</div>
</template>

<script>
import Icon from '@/components/utils/Icon.vue'
import { mapActions, mapGetters } from 'vuex'
import helpers from '@/utils/helpers.js'

export default {
	name: 'CustomerGroup',
	components: {
		Icon,
	},
	data() {
		return {
			expanded: false,
			show_delete_dataset_modal: false,
			hovered: false,
			'dataset-transition': false,
			'icon-transition': false,
			new_el_height: null,
			btns_wrapper_class_list: {
				'btns-wrapper': true,
				'rotated-change-parent-btn': false,
				hidden: false,
			},
			select_parent_btn_class_list: {
				'rotated-change-parent-btn': true,
				'change-parent-btn-transition': true,
				hidden: true,
				column: false,
			},
			add_dialog: false,
			add_dataset_name: null,
			edit_dialog: false,
			edited_dataset_name: null,
			delete_dialog: false,
		}
	},
	props: {
		data: Object,
	},
	computed: {
		...mapGetters(['parent_change_menu', 'pending_parent_change_cg', 'logged_user']),
		true_max_height() {
			let child_count = helpers.countChildren(this.getCgTreeData())
			return this.$el.offsetHeight * child_count
		},
		root_el_class_list() {
			return {
				'cg-element': true,
				'border-left': this.data.parentid,
				'border-bottom': this.pending_parent_change_cg.id === this.cg_data.id,
			}
		},
		title_el_class_list() {
			let child_cg_list = this.getCgTreeData().children
			return {
				'title-element': true,
				'cursor-pointer': child_cg_list,
				'cursor-default': !child_cg_list,
				'non-selectable': child_cg_list,
				'border-left':
					this.data.parentid && this.expanded && child_cg_list && this.hovered,
			}
		},
		icon_btn_class_list() {
			return {
				'rotate-180': !this.expanded,
				'icon-transition': this['icon-transition'],
			}
		},
		children_list_class_list() {
			let child_cg_list = this.getCgTreeData().children
			return {
				'children-list-element': true,
				collapsed: !child_cg_list || !this.expanded,
				'opacity-0': !this.expanded,
				'border-left': this.expanded && this.hovered,
				'dataset-transition': this['dataset-transition'],
			}
		},
		child_cg_list() {
			return this.getCgTreeData().children
		},
		cg_data() {
			return this.$store.getters.customer_groups.filter(
				(c) => c.id === this.data.id
			)[0]
		},
	},
	methods: {
		...mapActions([
			'deleteCustomerGroup',
			'createCustomerGroup',
			'updateCustomerGroup',
			'toggleParentChangeMenu',
			'handlePendingParentChange',
			'getCustomerGroups'
		]),
		resize() {
			this.resetMaxHeight()
			this.$emit('resize')
		},
		resetMaxHeight() {
			let children_list = document.querySelector(`#cg_${this.data.id}_children`)
			children_list.style.maxHeight = this.true_max_height + 'px'
		},
		getCgTreeData() {
			let tree_data = this.$store.getters.tree_data
			let cg = helpers.searchTree(tree_data[0], this.data.id)
			return cg
		},
		toggleCollapse() {
			if (!this.child_cg_list) {
				return
			}
			let children_list = document.querySelector(`#cg_${this.data.id}_children`)
			this.hovered = true
			if (!this.expanded) {
				this.expanded = true
				let height = children_list.scrollHeight
				children_list.style.maxHeight = height + 'px'
				setTimeout(() => {
					this.resetMaxHeight()
				}, 500)
			} else {
				this.expanded = false
			}
		},
		mouseEnter() {
			this.hovered = true
		},
		mouseLeave() {
			this.hovered = false
		},
		initStyles() {
			this.new_el_height = this.$el.offsetHeight
			if (!this.data.parentid) {
				this.expanded = true
			}
			requestAnimationFrame(() => {
				this['dataset-transition'] = true
				this['icon-transition'] = true
			})
		},
		showErrorNotification(msg) {
			this.$q.notify({
				timeout: 6700,
				message: msg,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
		async addDataset() {
			let response = await this.createCustomerGroup({
				name: this.add_dataset_name,
				parent: this.data.id,
			})
			this.add_dataset_name = null
			this.add_dialog = false
			if (response.error) {
				this.showErrorNotification(response.error_message)
				return
			}
			if (!this.expanded) {
				this.toggleCollapse()
			}
			this.$q.notify('Dataset added successfully.')
			this.$emit('resize')
		},
		async editDatasetName() {
			let cg = JSON.parse(JSON.stringify(this.cg_data))
			cg.name = this.edited_dataset_name
			let response = await this.updateCustomerGroup(cg)
			this.edit_dialog = false
			if (response.error) {
				this.showErrorNotification(response.error_message)
				return
			}
			this.$q.notify('The Dataset was renamed successfully.')
		},
		async deleteDataset() {
			let response = await this.deleteCustomerGroup(this.data.id)
			this.delete_dialog = false
			if (response.error) {
				this.showErrorNotification(response.error_message)
				return
			}
			this.$q.notify('The Dataset was removed successfully.')
			this.$emit('resize')
		},
		cancelParentChange() {
			this.toggleParentChangeMenu({})
		},
		toggleParentChangeBtns() {
			this.btns_wrapper_class_list.column = !this.btns_wrapper_class_list.column
			this.btns_wrapper_class_list.hidden = !this.btns_wrapper_class_list.hidden
			this.select_parent_btn_class_list.hidden =
				!this.select_parent_btn_class_list.hidden
		},
		changeParentClickHandler() {
			this.toggleParentChangeMenu(this.cg_data)
		},
		async selectParentClickHandler() {
			let response = await this.handlePendingParentChange(this.data.id)
			if (response.error) {
				this.showErrorNotification(response.error_message)
				return
			}
			this.$q.notify('The Dataset was moved successfully.')
			await this.getCustomerGroups()
			this.toggleParentChangeMenu()
			if (!this.expanded) {
				this.toggleCollapse()
			}
		},
	},
	created() {
		this.edited_dataset_name = this.data.label
	},
	mounted() {
		this.initStyles()
	},
	watch: {
		parent_change_menu: {
			deep: true,
			handler() {
				if (this.parent_change_menu) {
					this.btns_wrapper_class_list['rotated-change-parent-btn'] = true
					setTimeout(() => {
						this.toggleParentChangeBtns()
						setTimeout(() => {
							this.select_parent_btn_class_list[
								'rotated-change-parent-btn'
							] = false
						})
					}, 300)
				} else {
					// This if is for the newly added element once it gets transfered to a new parent
					if (!this.btns_wrapper_class_list.hidden) {
						return
					}
					this.select_parent_btn_class_list['rotated-change-parent-btn'] = true
					setTimeout(() => {
						this.toggleParentChangeBtns()
						setTimeout(() => {
							this.btns_wrapper_class_list['rotated-change-parent-btn'] = false
						})
					}, 300)
				}
			},
		},
		add_dialog: function() {
			this.add_dataset_name=null
		}
	},
}
</script>

<style lang="scss" scoped>
.dataset-transition {
	transition-property: opacity, max-height;
	transition-duration: 500ms;
	transition-timing-function: ease-in-out;
}
.icon-transition {
	transition: all 500ms ease-in-out;
}
.cg-element {
	padding-left: 1.5rem;
	text-align: left;
	padding-bottom: 0.25rem;
}
.border-left {
	border-left: 1px solid $grey-4;
}
.border-bottom {
	border-bottom: 1px solid $grey-8;
}
.title-element {
	padding: 0.5rem;
	padding-left: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-color: $grey-8;
	font-size: 1rem;
	&:hover {
		background-color: $grey-2;
	}
}
.children-list-element {
	overflow: hidden;
	border-color: $grey-8;
}
.collapsed {
	max-height: 0px !important;
}
.btns-wrapper {
	display: flex;
	align-items: center;
	margin-left: auto;
	transition: all 300ms ease-in-out;
}
.change-parent-btn-transition {
	transition: all 300ms ease-in-out;
}
.rotated-change-parent-btn {
	transform: rotateX(90deg);
}
</style>
>
